import { AnimatePresence } from "motion/react"
import * as motion from "motion/react-client"
import React from "react";
import './Viewer.css'

function Imageviewer({activeSelector, activeItem, items, viewerState,viewerClose}) {

    function previousButton()
    {
        if((activeItem-1)<0)
        {
            activeSelector(items.length-1);

        }
        else
        {
            activeSelector(activeItem-1);
        }
    }
    function nextButton()
    {
        if((activeItem+1)>=items.length)
        {
            activeSelector(0);
        }
        else
        {
            activeSelector(activeItem+1);
        }
    }
    return (
    <AnimatePresence>
    {viewerState? (<motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{duration:0.3}} className="viewer-holder">
        <motion.div initial={{ scale: 0.95}} animate={{ scale: 1 }} exit={{ scale: 0.95 }} transition={{duration:0.3}} className='viewer'>
            <div className='view-area'>
                <button className="slide-button" onClick={()=>previousButton()}><img alt="prev" src="Left.svg"/></button>
                    <Slideshow image={items[activeItem]}/>
                <button className="slide-button" onClick={()=>nextButton()}><img alt="next" src="Right.svg"/></button>
            </div>
            <div className='view-details'>
                <div className="view-titles">
                    <div className="item-title">{items[activeItem].title}</div>
                    <div className="item-duration">{items[activeItem].date}</div>
                </div>
                <div className="view-meta">
                    {/* <div>
                    {items[activeItem].colors.map(item=><div>{item}</div>)}
                    </div> */}
                </div>
            </div>
        </motion.div>
        <motion.div onClick={()=>viewerClose()} className="close-area"></motion.div>
    </motion.div>):null}
    </AnimatePresence>
    );
}

const Slideshow = ({ image }) => (
    <AnimatePresence>
        <motion.div
        key={image.key}
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}} className='item-image'>
      <img
        key={image.key}
        alt={image.title}
        src={image.image_url}
      />
      </motion.div>
    </AnimatePresence>
  )

export default Imageviewer;

