import React from "react";
import About from '../Sections/About';
import Hero from '../Sections/Hero';
import Projects from '../Sections/Projects';
import Hobbies from '../Sections/Hobbies';
// import Selector from '../Sections/Selector';
import Footer from '../Sections/Footer'

const Home=({handleIntersect})=> {
    
    return ( <div>
        {/* <Selector activeSection={activeSection}/> */}
        <Hero id="hero" onIntersect={handleIntersect}/>
        <About id="about" onIntersect={handleIntersect}/>
        <Projects id="projects" onIntersect={handleIntersect}/>
        <Hobbies id="hobbies" onIntersect={handleIntersect}/>
        <Footer/>
        </div>  );
}

export default Home;