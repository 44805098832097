import React, { useRef } from 'react';
import './Footer.css'
import emailjs from '@emailjs/browser';

function Footer() {
    const form = useRef();

    const SERVICE_KEY= "service_fn6x4lv";
    const TEMPLATE_KEY= "template_fc582w2";
    const PUBLIC_KEY= "MScgAnrSGSSYgGg5F";

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm(SERVICE_KEY, TEMPLATE_KEY, form.current, {
            publicKey: PUBLIC_KEY,
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };

    return ( 
    <div className="footer-wrap switzer">
        <div className='footer-main'>
            <div className='contact-me'>
                Contact me
                <span>Drop your mail if you are interested to collaborate with me!</span>
                <form ref={form} onSubmit={sendEmail} className='inputs-footer'><input className='input-mail' type='email' name='user_email' placeholder='email address'/>
                <input type='submit' value='↗' className='footer-button'/></form>
            </div>
            <div className='link-wrap'><div className='flex-links'>
                Navigate
                <a href='#hero'>Home</a>
                <a href='#about'>About</a>
                <a href='#projects'>Projects</a>
                <a href='#hobbies'>Hobbies</a>
            </div>
            <div className='flex-links'>
                Socials
                <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/bharathganesh26/'>LinkedIn</a>
                <a target="_blank" rel="noreferrer" href='https://www.behance.net/bharathganesh'>Behance</a>
                <a target="_blank" rel="noreferrer" href='https://dribbble.com/Bharathrico'>Dribbble</a>
                <a target="_blank" rel="noreferrer" href='https://www.instagram.com/dummy.designer/'>Instagram</a>
            </div>
            </div>
        </div>
            <span className='footer-detail'>Made with React, Figma and Love of design!</span>
    </div> );
}

export default Footer;