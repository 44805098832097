import './Hobby.css'
import { useNavigate  } from 'react-router-dom';
import * as motion from "motion/react-client"
import { AnimatePresence } from 'motion/react';

const Hobby= ({activeSelector, hobbyjson}) => {
    const navigate=useNavigate();
    function hobbynavigate(routelocation) {
      // Ensure scroll restoration is manual
      navigate.scrollRestoration = 'manual';
    
      // Scroll to the top of the page
      window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      
      setTimeout(() => {
        navigate(routelocation);
      }, 100); // Small delay to ensure scroll happens first
    }
    
    return (  
    <AnimatePresence>
    <motion.div key={hobbyjson.post.key} initial={{visibility:"hidden"}} animate={{visibility:"visible"}} exit={{visibility:"hidden"}} className="Hobby">
      <motion.div  initial={{opacity:0}} animate={{opacity:1}} className='content'>
        <div className='left-flex'>
          <div className='duration'>{hobbyjson.post.duration}</div>
          <div className='hobby-title'>
            <div>{hobbyjson.post.title}</div>
            <div className='sub-title'>{hobbyjson.post.subtitle}</div>
          </div>
        </div>
        <div className='right-flex' dangerouslySetInnerHTML={{__html:hobbyjson.post.description}}/>
      </motion.div>
      <div className="grid-post-container">
        {hobbyjson.gallery.items.map(item =><motion.div initial={{scale: 0.95,opacity:0}} animate={{scale: 1,opacity:1}} transition={{duration:0.5,delay:(item.key/10)}} onClick={()=>activeSelector(item.key)} key={item.key} className="grid-item"><div className='image-holder'><img alt={item.title} src={item.image_url}/></div></motion.div>)}
      </div>
      <div className='hobby-navigation'>
        Other Hobbies:
        <div className='hobbies-list'>
        {hobbyjson.post.key!==0?(<button onClick={()=>{hobbynavigate('/hobby-UI')}}>UI work</button>):null}
        {hobbyjson.post.key!==1?(<button onClick={()=>{hobbynavigate('/hobby-3D')}}>3D work</button>):null}
        {hobbyjson.post.key!==2?(<button onClick={()=>{hobbynavigate('/hobby-posters')}}>Poster</button>):null}
        {hobbyjson.post.key!==3?(<button onClick={()=>{hobbynavigate('/hobby-typo')}}>Typography</button>):null}
        </div>
      </div>
    </motion.div>
    </AnimatePresence>
    );
};

export default Hobby;