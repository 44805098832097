import React, { useState } from "react";
import Hobby from '../Sections/templates/Hobby';
import Footer from '../Sections/Footer'
import Imageviewer from "../Sections/Imageviewer";
import { useInView } from "react-intersection-observer";
// import Selector from '../Sections/Selector';

const Hobbythreed=({hobbyjson,handleIntersect})=> {
    const [activeItem, setActiveItem] = useState(0);
    const [viewerToggle, setviewerToggle] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    function activeSelector(itemIndex)
    {
        setActiveItem(itemIndex);
        if(!viewerToggle)
        {
        setScrollPosition(window.scrollY);
        // Disable scrolling and apply fixed positioning
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = "fixed";
        }
        setviewerToggle(true);
    }
        
    function viewerClose()
    {
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo({
            top: scrollPosition,
            behavior: 'instant'
          });
        setviewerToggle(false);
    }
    const { ref} = useInView({
        threshold: 0.2,
        triggerOnce: false,
        onChange: (inView) => {
          if (inView){
             handleIntersect("hobbies");
        }
        },
      });
    return ( <div ref={ref} id="hobbies">
        {/* <Selector activeSection="hobbies"/> */}
        <Imageviewer activeSelector={activeSelector} activeItem={activeItem} items={hobbyjson.gallery.items} viewerState={viewerToggle} viewerClose={viewerClose}/>
        {/* <div className={viewerToggle?"content-holder":""}> */}
        <Hobby activeSelector={activeSelector} hobbyjson={hobbyjson}/>
        <Footer/>
        {/* </div> */}
        </div>  );
}

export default Hobbythreed;