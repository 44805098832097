import './App.css';
import { useState } from 'react';
import { BrowserRouter,Route, Routes, } from 'react-router-dom';
import Home from './Pages/Home';
import ProjectOne from './Sections/templates/Project-one';
import HobbyUI from './Pages/Hobby-component';
import hobbyUIjson from './hobbies/hobby-ui.json';
import hobby3djson from './hobbies/hobby-3d.json';
import hobbyposterjson from './hobbies/hobby-poster.json';
import hobbytypojson from './hobbies/hobby-typo.json';
import Selector from '../src/Sections/Selector';

const App = () => {
  const [activeSection, setActiveSection] = useState("");
      
  function handleIntersect(id){
          setActiveSection(id); // Update active section when it comes into view
        };
  
  return (
    <BrowserRouter>
    <Selector activeSection={activeSection}/>
      <Routes>
        <Route path='/' element={<Home handleIntersect={handleIntersect}/>} />
        <Route path='/project/emonet' element={<ProjectOne/>} />
        <Route path='/project/medium' element={<ProjectOne/>} />
        <Route path='/project/spacemerch' element={<ProjectOne/>} />
        <Route path='/project/grocer' element={<ProjectOne/>} />
        <Route path='/hobby-UI' element={<HobbyUI hobbyjson={hobbyUIjson} handleIntersect={handleIntersect}/>} />
        <Route path='/hobby-3D' element={<HobbyUI hobbyjson={hobby3djson} handleIntersect={handleIntersect}/>} />
        <Route path='/hobby-posters' element={<HobbyUI hobbyjson={hobbyposterjson} handleIntersect={handleIntersect}/>} />
        <Route path='/hobby-typo' element={<HobbyUI hobbyjson={hobbytypojson} handleIntersect={handleIntersect}/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
