import './Projects.css'
import { useInView } from "react-intersection-observer";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';
import * as motion from "motion/react-client"

const Emonet = ({width}) => {
  
  const {  RiveComponent } = useRive
  ({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Emonet.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });


  return <RiveComponent />;
};

const Medium = ({width}) => {

  const {  RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Medium.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Grocerstreet = ({width}) => {

  const {  RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Grocerstreet.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Spacemerch = ({width}) => {
  
  const { RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Spacemerch.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Projects= ({ id, onIntersect}) => {
    const width = window.innerWidth<1025?true:false;
    const { ref } = useInView({
        threshold: 0.4,
        triggerOnce: false,
        onChange: (inView) => {
          if (inView) {
             onIntersect(id);
        }
        },
      });
    return (  <div id={id} ref={ref} className="Projects">
              <motion.div  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} className='title-section'>
              <div className='titles switzer'>A Peek at My Work</div>
               <hr></hr>
              </motion.div>
              <div className='grid-container'>
                <motion.a target="_blank" rel="noreferrer" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='https://www.behance.net/gallery/202244355/Medium-App-Redesign-with-GEN-AI-and-UX-Enhancements' className='rive-elements'><Medium width={width}/></motion.a>
                <motion.a target="_blank" rel="noreferrer" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='https://www.behance.net/gallery/195351373/Space-Merch-Case-study' className='rive-elements'><Spacemerch width={width}/></motion.a>
                <motion.a target="_blank" rel="noreferrer" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='https://www.behance.net/gallery/219794411/EmoNet-Brand-Guidelines' className='rive-elements'><Emonet width={width}/></motion.a>
                <motion.a target="_blank" rel="noreferrer" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='https://www.behance.net/gallery/158060501/Grocer-Street-UX-case-study' className='rive-elements'><Grocerstreet width={width}/></motion.a>
              </div>
        </div>);
};

export default Projects;