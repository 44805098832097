import './Selector.css'
import {animated, useSpring} from "react-spring"
import { useLocation,useNavigate } from 'react-router-dom';

const Selector = ({activeSection})=> {
  const location = useLocation();
  const navigate = useNavigate();
    const heroIndicator=useSpring({
      visibility: activeSection === "hero"? 'visible':'hidden',
      scale: activeSection === "hero"? 1 : 0,
      config:{mass:0.1, tension:100, friction:18}
    });
    const heroButton=useSpring({
      gap: activeSection === "hero"? "1rem":"0rem",
      config:{mass:0.01, tension:1000, friction:1}
    });
    const hobbiesIndicator=useSpring({
      visibility: activeSection === "hobbies"? 'visible':'hidden',
      scale: activeSection === "hobbies"? 1 : 0,
      config:{mass:0.1, tension:100, friction:18}
    });
    const hobbiesButton=useSpring({
      gap: activeSection === "hobbies"? "1rem":"0rem",
      config:{mass:0.1, tension:1000, friction:18}
    });
    const projectsIndicator=useSpring({
      visibility: activeSection === "projects"? 'visible':'hidden',
      scale: activeSection === "projects"? 1 : 0,
      config:{mass:0.1, tension:100, friction:18}
    });
    const projectsButton=useSpring({
      gap: activeSection === "projects"? "1rem":"0rem",
      config:{mass:0.1, tension:1000, friction:18}
    });
    const aboutIndicator=useSpring({
      visibility: activeSection === "about"? 'visible':'hidden',
      scale: activeSection === "about"? 1 : 0,
      config:{mass:0.1, tension:100, friction:18}
    });
    const aboutButton=useSpring({
      gap: activeSection === "about"? "1rem":"0rem",
      config:{mass:0.1, tension:1000, friction:18}
    });
    async function scrollposition(slocation) {
      if (location.pathname !== '/') {
        await navigate('/'); // Navigate to the target route
      }
      
      // Wait for the DOM to render after navigation
      setTimeout(() => {
        const element = document.getElementById(String(slocation));
        element?.scrollIntoView({
          behavior: 'smooth'
        });
      }, 100); // Delay ensures DOM is ready
    }
    return (  <div className='selector-wrap'><div className="selector">
            <animated.button style={heroButton}  className={`pill switzer ${activeSection === "hero"? "pill-active":""}`} onClick={()=>scrollposition('hero')}>
              <animated.div style={heroIndicator} className='select-indicator'/>
               Home </animated.button>
            <animated.button style={aboutButton}  className={`pill switzer ${activeSection === "about"? "pill-active":""}`} onClick={()=>scrollposition('about')}>
            <animated.div style={aboutIndicator} className='select-indicator'/>
               About </animated.button>
            <animated.button style={projectsButton}  className={`pill switzer ${activeSection === "projects"? "pill-active":""}`} onClick={()=>scrollposition('projects')}>
              <animated.div style={projectsIndicator} className='select-indicator'/>
               projects </animated.button>
            <animated.button style={hobbiesButton}  className={`pill switzer ${activeSection === "hobbies"? "pill-active":""}`} onClick={()=>scrollposition('hobbies')}>
              <animated.div style={hobbiesIndicator} className='select-indicator'/>
               hobbies </animated.button>
        </div></div>);
}

export default Selector;