import './About.css'
import { useInView } from "react-intersection-observer";
import * as motion from "motion/react-client"
import {isMobile, isTablet} from 'react-device-detect';
import React, { useState, useEffect } from "react";

const Contacts= ({ id, onIntersect}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1050);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1050);
    };
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
    const { ref} = useInView({
        threshold: 0.4,
        triggerOnce: false,
        onChange: (inView) => {
            
          if (inView)
            {
                 onIntersect(id);
            }
        },
      });
    return (  <div id={id} ref={ref} className="about-wrap">
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"2"}} viewport={{ once: true, amount: 'all' }}  className='title-section'>
              <div className='titles switzer'>About Me</div>
              <hr></hr>
            </motion.div>
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"2"}} viewport={{ once: true, amount: 'some' }}  className='about switzer'>
            <div className='about-left'>
              <div>
                I’m Bharath, an <span>Interaction Designer</span> with over <span>two years</span> of experience. I craft designs that are simple yet rich in detail, ensuring a delightful user experience. Currently based in <span>Chennai, India.</span>
              </div>
              <br/>
              <br/>
              <div className='about-contact'>
              <div>bharathganesh26899@gmail.com</div>
              <div>+91 6385456016</div>
              </div>
            </div>
            {(isTablet||isMobile||isSmallScreen)&&<hr/>}
            <div className='about-right'>
               I currently work at Thence, a Bangalore-based design agency, where I’ve had the opportunity to collaborate with top players in fintech, e-commerce, and the fitness industry.
               <br/><br/>
               Before this, I worked as a freelancer, taking on around 15 clients across UI/UX design, 3D design, and branding projects.
               <br/><br/>
               I have a keen interest in  gamification, AR/VR, and game design.
            </div>
            </motion.div>
        </div>);
};

export default Contacts;