import './Hobbies.css'
import { useInView } from "react-intersection-observer";
import { useRive, Layout, Fit, Alignment } from "@rive-app/react-canvas";
import {isMobile, isIOS, isAndroid} from 'react-device-detect';
import * as motion from "motion/react-client"


const Daily = ({width}) => {

  const { RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Daily.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Threed = ({width}) => {

  const {RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "3Dcard.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Poster = ({width}) => {

  const {RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Poster.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Type = ({width}) => {

  const {RiveComponent } = useRive({
    // Load a local riv `clean_the_car.riv` or upload your own!
    src: "Type.riv",
    // Be sure to specify the correct state machine (or animation) name
    stateMachines: "State Machine 1",
    // This is optional.Provides additional layout control.
    layout: new Layout({
      fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
      alignment: Alignment.Center,
    }),
    shouldDisableRiveListeners:isMobile||isIOS||isAndroid||width,
    autoplay: true,
  });

  return <RiveComponent />;
};

const Hobbies= ({ id, onIntersect}) => {
    const width = window.innerWidth<1025?true:false;
    const { ref} = useInView({
        threshold: 0.2,
        triggerOnce: false,
        onChange: (inView) => {
          if (inView){
             onIntersect(id);
        }
        },
      });
    return (  <div id={id} ref={ref} className="Hobbies">
              <motion.div  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} className='title-section'>
                            <div className='titles switzer'>Things I do when I am free</div>
                             <hr></hr>
                            </motion.div>
              <div className='grid-container'>
                <motion.a initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='/hobby-UI' className='rive-hobbies switzer'>Daily Designs<Daily width={width}/></motion.a>
                <motion.a initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='/hobby-3D' className='rive-hobbies switzer'>3D works<Threed width={width}/></motion.a>
                <motion.a initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='/hobby-posters' className='rive-hobbies switzer'>Poster<Poster width={width}/></motion.a>
                <motion.a initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{duration:"1"}} viewport={{ once: true, amount: 'all' }} href='/hobby-typo' className='rive-hobbies switzer'>Typography<Type width={width}/></motion.a>
              </div>
        </div>);
};

export default Hobbies;